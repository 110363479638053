import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import SuppliesState from '../types/SuppliesState'
import { buildFilterProductsQuery } from '@vue-storefront/core/helpers'
import { CategoryService } from '@vue-storefront/core/data-resolver/CategoryService'

export const suppliesStore: Module<SuppliesState, RootState> = {
  namespaced: true,
  state: {
    supplies: false,
    suppliesProducts: [],
    suppliesCurrentCategory: [],
    suppliesCurrentProduct: [],
    suppliesCategories: [],
    minified: false,
    cartMessages: []
  },
  mutations: {
    setSupplies (state, action) {
      state.supplies = action === true
    },
    setSuppliesProducts (state, items) {
      state.suppliesProducts = items
    },
    setCurrentCategory (state, category) {
      state.suppliesCurrentCategory = category
    },
    setCurrentProduct (state, product) {
      state.suppliesCurrentProduct = product
    },
    setIsMinified (state, minified) {
      state.minified = minified
    },
    setCartMessages (state, msgs) {
      state.cartMessages = msgs
    },
    setSuppliesCategories (state, categories) {
      state.suppliesCategories = categories
    }
  },
  getters: {
    getSuppliesProducts: state => state.suppliesProducts,
    getCurrentCategory: state => state.suppliesCurrentCategory,
    getCurrentProduct: state => state.suppliesCurrentProduct,
    getSuppliesCategories: state => state.suppliesCategories,
    isMinified: state => state.minified,
    getCartMessages: state => state.cartMessages
  },
  actions: {
    async openSupplies ({ commit, dispatch, state }, { product }) {
      commit('setIsMinified', false)
      commit('setSupplies', true)
      commit('ui/setOverlay', true, { root: true })
      await dispatch('setCurrentProduct', product)
    },
    closeSupplies ({ commit }) {
      commit('setCartMessages', [])
      commit('setIsMinified', false)
      commit('setSupplies', false)
      commit('ui/setOverlay', false, { root: true })
    },
    toggleMinified ({ commit, state }) {
      commit('setCartMessages', [])
      commit('setIsMinified', !state.minified)
      commit('ui/setOverlay', !state.minified, { root: true })
    },
    minify ({ commit, state }) {
      if (state.supplies) {
        commit('setCartMessages', [])
        commit('setIsMinified', true)
        commit('ui/setOverlay', false, { root: true })
      }
    },
    setCartMessages ({ commit }, msgs) {
      commit('setCartMessages', msgs)
    },
    setCurrentCategory ({ commit, dispatch }, category) {
      commit('setCurrentCategory', category)
      dispatch('initSuppliesProducts', category)
    },
    async setCurrentProduct ({ commit, state, dispatch }, product) {
      if (product && (!state.suppliesCurrentProduct || product.sku !== state.suppliesCurrentProduct.sku)) {
        const singleProduct = await dispatch('product/single', {
          options: {
            sku: product.sku
          },
          setCurrentProduct: false,
          selectDefaultVariant: false,
          assignDefaultVariant: false
        }, { root: true })
        singleProduct.parent = product
        commit('setCurrentProduct', singleProduct)
      }
    },
    async initSuppliesCategories ({ commit }, rootCategoryId) {
      const categories = await CategoryService.getCategories({ parentId: rootCategoryId })
      if (categories.length === 0) {
        console.warn('did not find categories for ' + rootCategoryId);
      }
      commit('setSuppliesCategories', categories)
      this.dispatch('supplies/setCurrentCategory', categories[0]);
    },
    async initSuppliesProducts ({ commit, dispatch, state }, category) {
      const filters = {}
      const suppliesProductsQuery = buildFilterProductsQuery(category, filters)

      let relatedSkus;
      if (state.suppliesCurrentProduct && category.filter_by_product) {
        relatedSkus = state.suppliesCurrentProduct.product_links
          .filter((product) => product.link_type === 'related')
          .sort((a, b) => a.position - b.position)
          .map((product) => product.linked_product_sku);

        suppliesProductsQuery.applyFilter({ key: 'sku', value: { in: relatedSkus } })
      }

      const response = await dispatch('product/findProducts', {
        query: suppliesProductsQuery,
        prefetchGroupProducts: false,
        updateState: false,
        sort: 'position',
        options: {
          separateSelectedVariant: true
        }
      }, { root: true })

      if (!response) {
        return
      }

      // apply sorting by relatedProductSku (which are sorted by related position)
      if (state.suppliesCurrentProduct && category.filter_by_product) {
        const mapOrder = (order, key) => (a, b) => order.indexOf(a[key]) > order.indexOf(b[key]) ? 1 : -1;
        response.items.sort(mapOrder(relatedSkus, 'sku'));
      }

      commit('setSuppliesProducts', response.items)
    }
  }
}
