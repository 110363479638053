import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import MatrixratesState from '../types/MatrixratesState'

export const matrixratesStorageKey = 'matrixrates'

export const matrixratesModule: Module<MatrixratesState, RootState> = {
  namespaced: true,
  state: {
    items: [],
    current: null,
    loading: false
  },
  getters,
  actions,
  mutations
}
