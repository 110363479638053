import { setupMultistoreRoutes } from '@vue-storefront/core/lib/multistore'
import App from './App.vue';
import routes from './router'
import Vue from 'vue'
import VueProgressBar from 'vue-progressbar'
import '@vue-storefront/core/lib/passive-listeners'
import { isServer, once } from '@vue-storefront/core/helpers'
import { cartModule } from './store/cart'
import { claimsStore } from 'theme/store/claims'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'

import VueScrollTo from 'vue-scrollto'

import { uiStore } from 'theme/store/ui'

/*
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations/esm/captureconsole'
import { RewriteFrames } from '@sentry/integrations/esm/rewriteframes'

const seenMessages = {};
const sentryEnv = typeof location !== 'undefined' ? location.origin : 'SSR-' + process.env.NODE_ENV
Sentry.init({
  Vue,
  dsn: 'https://7a1409a129236ea4e7bf244a83a3326d@sentry.netzkollektiv.com/2',
  integrations: [new Integrations.BrowserTracing(), new RewriteFrames(), new CaptureConsole({ levels: ['error'] })],
  tracesSampleRate: 1.0,
  environment: sentryEnv,
  beforeSend: (event) => {
    // prevent dual send
    if (event.message) {
      if (seenMessages[event.message]) {
        return null;
      }
      seenMessages[event.message] = true;
    }
    if (Object.values(seenMessages).length > 100) {
      return null;
    }
    if (event.message && event.message.indexOf('No participant for id:') !== -1) {
      return null;
    }
    if (event.message && event.message.match(/^\d{4}-\d{2}-.*?Z\s/)) {
      return {
        event,
        message: event.message.slice(24)
      };
    } else {
      return event;
    }
  }
});
*/

once('__VUE_EXTEND_DROPPOINT_VPB__', () => {
  if (!isServer) {
    Vue.use(VueScrollTo)
  }
  Vue.use(VueProgressBar)
})

const themeEntry = App
function initTheme (app, router, store, config, ssrContext) {
  app.$Lazyload.options.src = '/assets/placeholder.svg'
  // app.$Lazyload.options.loading = '/assets/placeholder.svg'
  app.$Lazyload.options.filter.webp = (listener, options) => {
    if (!options.supportWebp || !listener.src || !listener.src.startsWith('/assets')) {
      return
    }
    listener.src = listener.src
      .replace('.png', '.webp')
      .replace('.jpg', '.webp')
  }

  store.registerModule('themeCart', cartModule)
  // Register theme routes for the current store. In a single store setup this will add routes exactly as they are in the router definition file '[theme]/router/index.js'
  // In a multistore setup, it depends on the config setting 'appendStoreCode' for the current store
  // - true = the store code will be added to the front of all routes, e.g. name: 'de-checkout', path: '/de/checkout'
  // - false = the store code will not be added. In this case you need to define custom routes for each of your stores
  // You can also define custom routes to use a different component, for example for German storeView checkout
  // To do so, exclude the desired storeView from the config.storeViews.mapStoreUrlsFor, set appendStoreCode = false, and map all the urls by your own like:
  // { name: 'de-checkout', path: '/checkout', component: CheckoutCustomized }
  // The 4th parameter is the route priority - a higher number will ensure the theme routes override any module routes. The default is 0.
  setupMultistoreRoutes(config, router, routes, 10)

  StorageManager.init('claims');
  store.registerModule('claims', claimsStore);
  store.registerModule('ui', uiStore);
}

export {
  themeEntry,
  initTheme
}
