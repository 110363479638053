import config from 'config'
import { Route } from 'vue-router'
import rootStore from '@vue-storefront/core/store'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

function getUri (cmsUrl, routeTo) {
  let uri = cmsUrl + '/vue/cart/sync/token/' + rootStore.state.user.token + '/cart/' + rootStore.state.cart.cartServerToken
  if (typeof routeTo.params.paypal !== 'undefined') {
    uri += '?paypal=1'
  }
  return uri
}

export function beforeEach (to: Route, from: Route, next) {
  const externalCheckoutConfig = { ...config.externalCheckout };
  const cmsUrl: string = externalCheckoutConfig.cmsUrl;
  const stores = externalCheckoutConfig.stores;
  const storeCode = currentStoreView().storeCode
  const multistoreEnabled: boolean = config.storeViews.multistore

  if (multistoreEnabled) {
    if (storeCode in stores) {
      const uri = getUri(stores[storeCode].cmsUrl, to)
      if (to.name === storeCode + '-checkout') {
        return window.location.replace(uri)
      }
      if (to.name === 'checkout') {
        return window.location.replace(uri)
      }
    }
    return next()
  }
  if (to.name === 'checkout') {
    const uri = getUri(cmsUrl, to)
    return window.location.replace(uri)
  }
  return next()
}
