import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'
import { Logger } from '@vue-storefront/core/lib/logger'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { CartService } from '@vue-storefront/core/data-resolver'
import { createCartItemForUpdate } from '@vue-storefront/core/modules/cart/helpers'

const mergeActions = {
  async updateServerItem ({ getters, dispatch, commit }, { item }) {
    const cartItem = createCartItemForUpdate(item)
    const event = await CartService.updateItem(getters.getCartToken, cartItem)
    const wasUpdatedSuccessfully = event.resultCode === 200

    const result = event.result

    if (wasUpdatedSuccessfully) {
      dispatch('syncTotals')
    }

    if (!wasUpdatedSuccessfully && (item.server_item_id || item.item_id)) {
      await dispatch('restoreQuantity', { product: item })
      return false
    }
    if (!wasUpdatedSuccessfully) {
      commit(types.CART_DEL_ITEM, { product: item })
      return false
    }

    const serverItem = result
    const product = {
      ...item,
      ...{
        server_item_id: serverItem.item_id,
        server_quote_id: serverItem.quote_id,
        prev_qty: cartItem.qty,
        product_option: serverItem.product_option,
        type_id: serverItem.product_type
      }
    }

    await dispatch('updateItem', { product })
    EventBus.$emit('cart-after-itemchanged', { item: cartItem })

    return true
  },
  async addServerItems ({ dispatch }, { serverItems }) {
    for (const serverItem of serverItems) {
      await dispatch('addServerItem', { serverItem })
    }
  },
  async addServerItem ({ dispatch, commit }, { serverItem }) {
    Logger.info('No client item for' + serverItem.sku, 'cart')()

    const product = await dispatch('getProductVariant', { serverItem })

    if (product) {
      commit(types.CART_ADD_ITEM, {
        product: { ...product }
      })
      Logger.debug('Product variant for given serverItem has not found', 'cart', serverItem)()
    }

    return []
  }
}

export default mergeActions
