import Vue from 'vue'
import VueGtm from 'vue-gtm'

import { once, isServer } from '@vue-storefront/core/helpers'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { Logger } from '@vue-storefront/core/lib/logger'

import { googleTagManagerModule } from './store'
import { afterRegistration, isEnabled } from './hooks/afterRegistration'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export const KEY = 'google-tag-manager'

export const GoogleTagManagerModule: StorefrontModule = function ({ store, router, appConfig }) {
  const multistoreEnabled: boolean = appConfig.storeViews.multistore
  const { storeCode } = currentStoreView()

  let config = appConfig.googleTagManager
  if (multistoreEnabled && typeof appConfig.googleTagManager.stores[storeCode] !== 'undefined') {
    config = appConfig.googleTagManager.stores[storeCode]
  }

  if (isEnabled(appConfig.googleTagManager.id)) {
    const win = window as any;
    win.dataLayer = win.dataLayer || [];

    function gtag () {
      // eslint-disable-next-line prefer-rest-params
      win.dataLayer.push(arguments);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    gtag('consent', 'default', {
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      ad_storage: 'denied',
      analytics_storage: 'denied',
      wait_for_update: 2000 // milliseconds to wait for update
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    gtag('set', 'ads_data_redaction', true);

    /// Bing Consent Mode
    win.uetq = win.uetq || [];
    win.uetq.push('consent', 'default', {
      ad_storage: 'denied'
    });

    once('__VUE_EXTEND_GTM__', () => {
      Vue.use(VueGtm, {
        enabled: true,
        id: config.id,
        debug: config.debug,
        vueRouter: router
      })
    })
  } else {
    Logger.warn('Google Tag Manager extensions is not working. Ensure Google Tag Manager container ID is defined in config', 'GTM')()
  }

  store.registerModule(KEY, googleTagManagerModule)

  afterRegistration(appConfig, store, router)
}
