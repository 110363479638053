import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'
import { Logger } from '@vue-storefront/core/lib/logger'
import { isServer } from '@vue-storefront/core/helpers'
import config from 'config'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { CartService } from '@vue-storefront/core/data-resolver'
import i18n from '@vue-storefront/i18n'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { cartHooksExecutors } from '../../hooks'

const synchronizeActions = {
  async load ({ commit, dispatch }) {
    if (isServer) return

    dispatch('setDefaultCheckoutMethods')
    dispatch('synchronizeCart')
  },
  updateCart ({ commit }, { items }) {
    commit(types.CART_LOAD_CART, items)
  },
  async synchronizeCart ({ commit, dispatch }) {
    const token = await StorageManager.get('cart')
      .getItem('current-cart-token')

    if (token) {
      try {
        commit(types.CART_LOAD_CART_SERVER_TOKEN, token)
        Logger.info('getting cart from server', 'cache', token)()
        await dispatch('sync')
        dispatch('syncTotals')
      } catch (e) {
        commit(types.CART_LOAD_CART_SERVER_TOKEN, null)
      }
    }
    await dispatch('create')
  },
  async sync ({ getters, rootGetters, commit, dispatch, state }) {
    commit(types.CART_SET_SYNC)

    const { result, resultCode } = await CartService.getItems()
    const serverItems = result

    if (resultCode === 200) {
      const result = await dispatch('addServerItems', {
        serverItems
      })
      return result
    }
    throw new Error()
  }
}

export default synchronizeActions
