import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import MatrixratesState from '../types/MatrixratesState'

const mutations: MutationTree<MatrixratesState> = {
  [types.MATRIXRATES_UPDATE_MATRIXRATE] (state, matrixrates) {
    state.items = matrixrates || []
  },
  [types.MATRIXRATES_SET_CURRENT] (state, current) {
    state.current = current
  },
  [types.MATRIXRATES_ADD_MATRIXRATE] (state, matrixrate) {
    const record = state.items.find(c => c.id === matrixrate.id)
    if (!record) {
      state.items.push(matrixrate)
    }
  },
  [types.MATRIXRATES_LOADING] (state, loading) {
    state.loading = loading || false
  }
}

export default mutations
