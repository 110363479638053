import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { isServer } from '@vue-storefront/core/helpers'

const consentNotRequiredUris = () => {
  return ['/impressum', '/datenschutzerklaerung']
}

const getUsercentrics = () => {
  return new Promise((resolve, reject) => {
    const checkIfUCLoaded = () => {
      const win = window as any
      if (typeof win.UC_UI !== 'undefined') {
        resolve(win.UC_UI)
      } else {
        setTimeout(() => {
          checkIfUCLoaded()
        }, 50)
      }
    }

    checkIfUCLoaded()
  })
}

export const UsercentricsModule: StorefrontModule = async function ({ router }) {
  if (isServer) {
    return;
  }

  router.afterEach(async (to, from) => {
    const uc : any = await getUsercentrics()
    if (consentNotRequiredUris().includes(to.fullPath)) {
      uc.closeCMP();
      console.log('uc closed!');
    } else if (uc.isConsentRequired()) {
      uc.showFirstLayer();
    }
  })
}
