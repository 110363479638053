import { removeStoreCodeFromRoute, currentStoreView, localizedDispatcherRouteName } from '@vue-storefront/core/lib/multistore'
import { Payload } from '../vsf-mapping-fallback/types/Payload'
import * as types from '@vue-storefront/core/modules/catalog-next/store/category/mutation-types'
import { SearchQuery } from 'storefront-query-builder'
import { AsyncDataLoader } from '@vue-storefront/core/lib/async-data-loader';
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers';
import { Logger } from '@vue-storefront/core/lib/logger';
import { isServer } from '@vue-storefront/core/helpers';
import { router } from '@vue-storefront/core/app'
import { RawLocation } from 'vue-router';

export const forCategory = async ({ dispatch, commit }, { url }: Payload) => {
  const { storeCode, appendStoreCode } = currentStoreView()
  url = (removeStoreCodeFromRoute(url.startsWith('/') ? url.slice(1) : url) as string)
  url = url.replace(/\/$/, '')
  try {
    const category = await dispatch('category/single', { key: 'url_path', value: url }, { root: true })
    if (category !== null) {
      if (category.product_count === 1) {
        const productQuery = new SearchQuery()
        productQuery.applyFilter({
          key: 'category_ids',
          value: { in: category.id }
        })

        const items = await dispatch('product/findProducts', {
          query: productQuery,
          options: {
            separateSelectedVariant: true
          }
        }, { root: true })

        const product = items.items[0]
        product.parentSku = product.sku
        // product.sku = product.sku
        const parentUrl = formatProductLink(product, currentStoreView().storeCode)

        if (isServer) {
          AsyncDataLoader.push({
            execute: async ({ context }) => {
              if (context) {
                context.server.response.redirect(301, parentUrl)
              }
            }
          })
          return undefined;
        }
        /* return {
          name: localizedDispatcherRouteName(product.type_id + '-product', storeCode, appendStoreCode),
          params: {
            slug: product.slug,
            parentSku: product.sku,
            childSku: product.sku
          }
        } */
      }
      return {
        name: localizedDispatcherRouteName('category', storeCode, appendStoreCode),
        params: {
          slug: category.slug
        }
      }
    }
  } catch {
    return undefined
  }
}

export const forCms = async ({ dispatch }, { url, params }: Payload) => {
  const slug = url.substr(1)

  let cmsPage = null;
  try {
    cmsPage = await dispatch('cmsPage/single', {
      value: slug,
      setCurrent: true
    }, { root: true });
  } catch (e) {}

  if (cmsPage) {
    return {
      name: 'cms-page',
      params: {
        slug
      }
    }
  }
}
