import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { isServer } from '@vue-storefront/core/helpers'
import { coreHooks } from '@vue-storefront/core/hooks'

export const OfflineConversionsModule: StorefrontModule = function ({ store, router }) {
  StorageManager.init('offlineconversions')

  coreHooks.afterAppInit(async () => {
    if (isServer) return

    const params = new URL(location.href).searchParams;
    const conversionId = [params.get('gclid'), params.get('wbraid'), params.get('gbraid')].filter((e) => { return e; }).shift()
    if (conversionId) {
      StorageManager.get('offlineconversions').setItem('conversions', {
        id: conversionId,
        name: 'OCI - purchase',
        click_date: Date.now()
      });
    }
  })
}
