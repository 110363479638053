import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'
import { Logger } from '@vue-storefront/core/lib/logger'
import {
  prepareProductsToAdd,
  productsEquals,
  validateProduct
} from '@vue-storefront/core/modules/cart/helpers'
import { cartHooksExecutors } from './../../hooks'
import { CartService } from '@vue-storefront/core/data-resolver'
import CartItem from '@vue-storefront/core/modules/cart/types/CartItem';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

const itemActions = {
  async configureItem (context, { product, configuration }) {
    const { commit, dispatch, getters } = context
    const variant = await dispatch('product/getProductVariant', {
      product,
      configuration
    }, { root: true })

    const itemWithSameSku = getters.getCartItems.find(item => item.sku === variant.sku)

    if (itemWithSameSku && product.sku !== variant.sku) {
      Logger.debug('Item with the same sku detected', 'cart', { sku: itemWithSameSku.sku })()
      commit(types.CART_DEL_ITEM, { product: itemWithSameSku })
      product.qty = parseInt(product.qty) + parseInt(itemWithSameSku.qty)
    }

    commit(types.CART_UPD_ITEM_PROPS, { product: { ...product, ...variant } })
  },
  async updateItem ({ commit }, { product }) {
    commit(types.CART_UPD_ITEM_PROPS, { product })
  },
  getItem ({ getters }, { product }) {
    return getters.getCartItems.find(p => productsEquals(p, product))
  },
  async addItem ({ dispatch, commit }, { productToAdd, qty = null }) {
    EventBus.$emit('cart-loading')

    const { cartItem } = cartHooksExecutors.beforeAddToCart({ cartItem: productToAdd })
    commit(types.CART_ADDING_ITEM, { isAdding: true })

    const product = prepareProductsToAdd(cartItem)[0]
    const errors = validateProduct(product)

    if (errors.length === 0) {
      commit(types.CART_ADD_ITEM, {
        product: { ...product },
        specifiedQty: qty
      })
    }
    const clientItem = await dispatch('getItem', { product })
    const result = await dispatch('updateServerItem', { item: clientItem })

    commit(types.CART_ADDING_ITEM, { isAdding: false })
    cartHooksExecutors.afterAddToCart(result)
    return result
  },
  async removeItem ({ commit, dispatch, getters }, payload) {
    EventBus.$emit('cart-loading')

    const product = payload.product || payload
    const { cartItem } = cartHooksExecutors.beforeRemoveFromCart({ cartItem: product })

    Logger.info('Removing product from cart', 'cart')()
    commit(types.CART_DEL_ITEM, { product: cartItem })

    if (cartItem.server_item_id) {
      const serverItem = {
        sku: cartItem.sku,
        item_id: cartItem.server_item_id
      } as any as CartItem
      Logger.log('Removing server item' + serverItem.sku + serverItem.item_id, 'cart')()
      const resp = await CartService.deleteItem(getters.getCartToken, serverItem)
      cartHooksExecutors.afterRemoveFromCart(resp)
      dispatch('syncTotals')
      return resp
    }
  },
  async restoreQuantity ({ dispatch }, { product }) {
    const currentCartItem = await dispatch('getItem', { product })
    if (currentCartItem) {
      Logger.log('Restoring qty after error' + product.sku + currentCartItem.prev_qty, 'cart')()
      if (currentCartItem.prev_qty > 0) {
        await dispatch('updateItem', {
          product: {
            ...product,
            qty: currentCartItem.prev_qty
          }
        })
        EventBus.$emit('cart-after-itemchanged', { item: currentCartItem })
      } else {
        await dispatch('removeItem', { product: currentCartItem })
      }
    }
  },
  async updateQuantity ({ commit, dispatch }, { product, qty }) {
    EventBus.$emit('cart-loading')
    await dispatch('addItem', { productToAdd: { ...product }, qty })
  }
}

export default itemActions
