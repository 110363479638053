import { ActionTree } from 'vuex'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import * as types from './mutation-types'
import RootState from '@vue-storefront/core/types/RootState';
import MatrixratesState from '../types/MatrixratesState'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { matrixratesStorageKey } from './'
import { createMatrixratesLoadingQuery } from '../helpers'

const actions: ActionTree<MatrixratesState, RootState> = {
  async loadMatrixrates ({ commit, state }, { filterValues = null, filterField = 'id', size = 150, start = 0, skipCache = false }) {
    if (state.loading || state.items.length) {
      return state.items
    }

    const query = createMatrixratesLoadingQuery({ filterField, filterValues })
    commit(types.MATRIXRATES_LOADING, true)
    return quickSearchByQuery({ query, entityType: 'matrixrates' }).then((response) => {
      commit(types.MATRIXRATES_UPDATE_MATRIXRATE, response.items)
      commit(types.MATRIXRATES_LOADING, false)
    })
  },
  async loadFromCache ({ commit }, { key, value, setCurrent }) {
    const matrixratesStorage = StorageManager.get('matrixrates')
    const storedItems = await matrixratesStorage.getItem(matrixratesStorageKey)

    if (storedItems) {
      commit(types.MATRIXRATES_UPDATE_MATRIXRATE, storedItems)
      const resp = storedItems.find(p => p[key] === value)
      if (!resp) {
        throw new Error('Matrixrates query returned empty result')
      }

      if (setCurrent) {
        commit(types.MATRIXRATES_SET_CURRENT, resp)
      }

      return resp
    }

    throw new Error('Matrixrates query returned empty result')
  },
  addItem ({ commit }, page) {
    commit(types.MATRIXRATES_ADD_MATRIXRATE, page)
  }
}

export default actions
