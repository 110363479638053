import config from 'config'
import CartItem from '@vue-storefront/core/modules/cart/types/CartItem';

const createCartItemForUpdate = (item: CartItem): CartItem => {
  const sku = item.parentSku && config.cart.setConfigurableProductOptions ? item.parentSku : item.sku
  const cartItem = {
    ...((item && item.server_item_id) ? { item_id: item.server_item_id } : {}),
    ...((item && item.server_item_id) ? {} : { sku }), // if server_item_id is set omit sku (otherwise Magento adds to quantity)
    qty: item.qty,
    product_option: item.product_option
  } as any as CartItem

  return cartItem
}

export default createCartItemForUpdate
