import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'
import { Logger } from '@vue-storefront/core/lib/logger'
import { CartService } from '@vue-storefront/core/data-resolver'

const connectActions = {
  toggleMicrocart ({ commit }) {
    commit(types.CART_TOGGLE_MICROCART)
  },
  async clear ({ dispatch, getters }, { disconnect = true, sync = true } = {}) {
    if (sync) {
      getters.getCartItems.forEach((item) => {
        dispatch('removeItem', { product: item })
      })
      // await commit(types.CART_LOAD_CART, [])
    }
    if (disconnect) {
      await dispatch('disconnect')
    }
  },
  disconnect ({ commit }) {
    commit(types.CART_LOAD_CART_SERVER_TOKEN, null)
  },
  async authorize ({ dispatch, getters }) {
    const coupon = getters.getCoupon.code
    if (coupon) {
      await dispatch('removeCoupon', { sync: false })
    }

    await dispatch('connect', { guestCart: false, mergeQty: true })

    if (coupon) {
      await dispatch('applyCoupon', coupon)
    }
  },
  async connect ({ dispatch, commit }, { guestCart = false }) {
    const { result, resultCode } = await CartService.getCartToken(guestCart)

    if (resultCode === 200) {
      Logger.info('Server cart token created.', 'cart', result)()
      commit(types.CART_LOAD_CART_SERVER_TOKEN, result)

      return dispatch('sync')
    }
  },
  /**
   * Create cart token when there are products in cart and we don't have token already
   */
  async create ({ dispatch, getters }) {
    // const storedItems = getters.getCartItems || []
    const cartToken = getters.getCartToken
    if (!cartToken) {
      Logger.info('Creating server cart token', 'cart')()
      return dispatch('connect', { guestCart: false })
    }
  }
}

export default connectActions
