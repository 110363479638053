import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { suppliesStore } from './store'

export const SuppliesModule: StorefrontModule = async function ({ app, store, router, moduleConfig, appConfig }) {
  store.registerModule('supplies', suppliesStore)

  router.afterEach((to, from) => {
    // console.log('supplies-route: ' + from.name + ' => ' + to.name)
  })
}
