import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import MatrixratesState from '../types/MatrixratesState'
import { Logger } from '@vue-storefront/core/lib/logger'

const getters: GetterTree<MatrixratesState, RootState> = {
  getMatrixrates: (state): object[] => state.items,
  hasItems: (state) => state.items && state.items.length > 0,
  findItems: (state) => ({ key, value }) => state.items.find(p => p[key] === value)
}

export default getters
