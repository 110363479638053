import * as types from '../store/mutation-types'
import { Logger } from '@vue-storefront/core/lib/logger'

import { StorageManager } from '@vue-storefront/core/lib/storage-manager'

export const cartCacheHandlerPlugin = (mutation, state) => {
  const type = mutation.type;

  if (
    type.endsWith(types.CART_LOAD_CART_SERVER_TOKEN)
  ) {
    return StorageManager.get('cart').setItem('current-cart-token', state.cart.cartServerToken).catch((reason) => {
      Logger.error(reason)()
    })
  }
}
