import Vue from 'vue'
import VueGtm from 'vue-gtm'
import { Store } from 'vuex'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isServer } from '@vue-storefront/core/helpers'
import { addRemarketingInfo, trackRemarketing } from '../helpers/track'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

export const isEnabled = (gtmId: string | null) => {
  return typeof gtmId === 'string' && gtmId.length > 0 && !isServer
}

export function afterRegistration (config, store: Store<any>, router) {
  const GTM: typeof VueGtm = (Vue as any).gtm
  const trackPageView = (path, title) => {
    GTM.trackEvent({
      event: 'pageview',
      pagePath: path,
      pageTitle: title
    });
  }

  const getGa4Product = (item) => {
    const product: any = {}

    const attributeMap: string[]|Record<string, any>[] = config.googleTagManager.product_attributes_ga4
    attributeMap.forEach(attribute => {
      const isObject = typeof attribute === 'object'
      const attributeField = isObject ? Object.keys(attribute)[0] : attribute
      const attributeName = isObject ? Object.values(attribute)[0] : attribute

      if (item.hasOwnProperty(attributeField) || product.hasOwnProperty(attributeName)) {
        const value = item[attributeField] || product[attributeName]
        if (value) {
          product[attributeName] = value
        }
      }
    })

    const { category } = item
    if (category && category.length > 0) {
      product.category = category.slice(-1)[0].name
    }
    return product
  }

  if (isEnabled(config.googleTagManager.id)) {
    const storeView = currentStoreView()
    const currencyCode = storeView.i18n.currencyCode

    let lastProduct = null

    const getProduct = (item) => {
      const product: any = {}

      const attributeMap: string[]|Record<string, any>[] = config.googleTagManager.product_attributes
      attributeMap.forEach(attribute => {
        const isObject = typeof attribute === 'object'
        const attributeField = isObject ? Object.keys(attribute)[0] : attribute
        const attributeName = isObject ? Object.values(attribute)[0] : attribute

        if (item.hasOwnProperty(attributeField) || product.hasOwnProperty(attributeName)) {
          const value = item[attributeField] || product[attributeName]
          if (value) {
            product[attributeName] = value
          }
        }
      })

      const { category } = item
      if (category && category.length > 0) {
        product.category = category.slice(-1)[0].name
      }

      return product
    }

    router.afterEach((to, from) => {
      const observer = new MutationObserver((mutations) => {
        trackPageView(
          location.pathname,
          (mutations[0].target as HTMLElement).innerText
        )

        if (to.name === 'home') {
          addRemarketingInfo({
            ecomm_pagetype: 'home'
          })
        }
        trackRemarketing();

        observer.disconnect();
      });

      observer.observe(
        document.querySelector('title'),
        { subtree: true, characterData: true, childList: true }
      )
    });

    EventBus.$on('cart-add-item', product => {
      GTM.trackEvent({
        event: 'addToCart',
        ecommerce: {
          currencyCode,
          add: {
            products: [getProduct(product)]
          }
        }
      });

      // GA4 Implementation
      GTM.trackEvent({
        event: 'add_to_cart',
        ecommerce: {
          items: [getGa4Product(product)]
        }
      });
    });

    store.subscribe(({ type, payload }, state) => {
      // Adding a Product to a Shopping Cart
      /*
      if (type === 'cart/cart/ADD') {
        GTM.trackEvent({
          event: 'addToCart',
          ecommerce: {
            currencyCode,
            add: {
              products: [getProduct(payload.product)]
            }
          }
        });

        // GA4 Implementation
        GTM.trackEvent({
          event: 'add_to_cart',
          ecommerce: {
            items: [getGa4Product(payload.product)]
          }
        });
      }
      */

      if (type === 'cart/cart/UPD_TOTALS') {
        // Remarketing
        const items = store.getters['cart/getCartItems']
        const totals = payload.totals
        addRemarketingInfo({
          ecomm_pagetype: 'cart',
          // ecomm_pcat: [payload.category.map(category => category.name)],
          ecomm_prodid: [items.map(item => item.sku)],
          ecomm_pname: [items.map(item => item.name)],
          ecomm_pvalue: [totals.items.map(item => item.price_incl_tax)],
          ecomm_totalvalue: payload.totals.base_grand_total
        })
        trackRemarketing()
      }

      // Removing a Product from a Shopping Cart
      if (type === 'cart/cart/DEL') {
        GTM.trackEvent({
          event: 'removeFromCart',
          ecommerce: {
            remove: {
              products: [getProduct(payload.product)]
            }
          }
        });

        // GA4 Implementation
        GTM.trackEvent({
          event: 'remove_from_cart',
          ecommerce: {
            items: [getGa4Product(payload.product)]
          }
        });
      }

      // Measuring Views of Product Details
      if (
        type === 'product/product/SET_CURRENT' &&
          (!lastProduct || lastProduct.id !== payload.id)
      ) {
        lastProduct = payload

        GTM.trackEvent({
          ecommerce: {
            detail: {
              actionField: { list: '' }, // 'detail' actions have an optional list property.
              products: [getProduct(payload)]
            }
          }
        });

        // GA4 Implementation
        GTM.trackEvent({
          event: 'view_item',
          ecommerce: {
            items: [getGa4Product(payload)]
          }
        });

        addRemarketingInfo({
          ecomm_pagetype: 'product',
          ecomm_pcat: [payload.category.map(category => category.name)],
          ecomm_prodid: [payload.sku],
          ecomm_pname: [payload.name]
        })
      }

      // Measuring Purchases
      /* if (type === 'order/orders/LAST_ORDER_CONFIRMATION') {
        const orderId = payload.confirmation.backendOrderId
        const products = payload.order.products.map(product => getProduct(product))
        store.dispatch(
          'user/getOrdersHistory',
          { refresh: true, useCache: false }
        ).then(() => {
          const orderHistory = state.user.orders_history
          const order = state.user.orders_history ? orderHistory.items.find((order) => order.entity_id.toString() === orderId) : null
          GTM.trackEvent({
            ecommerce: {
              purchase: {
                actionField: {
                  id: orderId,
                  affiliation: order ? order.store_name : '',
                  revenue: order ? order.total_due : state.cart.platformTotals && state.cart.platformTotals.base_grand_total ? state.cart.platformTotals.base_grand_total : '',
                  tax: order ? order.total_due : state.cart.platformTotals && state.cart.platformTotals.base_tax_amount ? state.cart.platformTotals.base_tax_amount : '',
                  shipping: order ? order.total_due : state.cart.platformTotals && state.cart.platformTotals.base_shipping_amount ? state.cart.platformTotals.base_shipping_amount : '',
                  coupon: ''
                },
                products
              }
            }
          })
        })
      } */
    })
  }
}
