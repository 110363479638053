import i18n from '@vue-storefront/i18n'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { cartStore } from '@vue-storefront/core/modules/cart/store'
import { cartCacheHandlerPlugin } from '@vue-storefront/core/modules/cart/helpers';
import { isServer } from '@vue-storefront/core/helpers'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import { coreHooks } from '@vue-storefront/core/hooks';
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'
import { CartService } from '@vue-storefront/core/data-resolver'
import { Payload } from '../vsf-mapping-fallback/types/Payload'
import rootStore from '@vue-storefront/core/store'

const loadSharedCart = async (crypt, store, router) => {
  const { storeCode } = currentStoreView()

  const { result, resultCode } = await CartService.getCartToken(false)
  if (resultCode === 200) {
    const cartStorage = StorageManager.get('cart')
    cartStorage.setItem('current-cart-token', result)

    const userToken = rootStore.getters['user/getUserToken']
    const cartToken = userToken ? 'mine' : result;
    await fetch('/rest/' + storeCode + '/V1/share-cart/' + cartToken + '/load/' + crypt, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken
      }
    })
    await store.dispatch('cart/setDefaultCheckoutMethods')
    await store.dispatch('cart/synchronizeCart')
    await store.dispatch('cart/syncTotals')

    store.dispatch('ui/toggleMicrocart')
    store.dispatch('notification/spawnNotification', {
      type: 'success',
      message: i18n.t('Saved cart was successfully loaded'),
      action1: { label: i18n.t('OK') }
    })
    router.push(localizedRoute('/'))
  }
}

export const forShareCart = async (context, { url, params }: Payload) => {
  const match = url.match(/\/sharecart\/cart\/index\/crypt\/([\w]+)\/*/)
  if (match) {
    return {
      name: 'home',
      params: {
        shareCartCrypt: match[1]
      }
    }
  }
}

export const CartModule: StorefrontModule = function ({ store, router }) {
  StorageManager.init('cart')

  store.registerModule('cart', cartStore)

  store.subscribe(cartCacheHandlerPlugin)

  coreHooks.afterAppInit(async () => {
    if (isServer) return

    const match = window.location.pathname.match(/\/sharecart\/cart\/index\/crypt\/([\w]+)\/*/)
    if (match) {
      return loadSharedCart(match[1], store, router)
    }
    return store.dispatch('cart/load')
  })
}
