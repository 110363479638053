import { SearchQuery } from 'storefront-query-builder'
import { Module } from 'vuex'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'

import RootState from '@vue-storefront/core/types/RootState'
import ConfigState from '../types/ConfigState'
import Vue from 'vue'

export const configStore: Module<ConfigState, RootState> = {
  namespaced: true,
  state: {
    config: []
  },
  mutations: {
    setConfigValues (state, configValues) {
      state.config = configValues || []
    }
  },
  getters: {
    getConfigValue: (state) => (path) => {
      if (!state.config) {
        return null
      }
      const value = state.config.find(p => p.config_path === path)
      if (value) {
        return value.config_value
      }
    }
  },
  actions: {
    async loadConfigData ({ commit }) {
      const query = new SearchQuery()

      const response = await quickSearchByQuery({ entityType: 'config', query })
      if (response) {
        const items = response.items
        commit('setConfigValues', items)
      }
    }
  }
}
