import Vue from 'vue'
import VueGtm from 'vue-gtm'

let remarketingParams: any = {};

export function addRemarketingInfo (params: any) {
  remarketingParams = params
}

export function trackRemarketing () {
  window.setTimeout(() => {
    if (!remarketingParams.ecomm_pagetype) {
      remarketingParams.ecomm_pagetype = 'other'
    }

    const GTM: typeof VueGtm = (Vue as any).gtm
    GTM.trackEvent({
      event: 'remarketingTriggered',
      google_tag_params: remarketingParams
    })

    remarketingParams = {}
  }, 1000);
}
