import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { bannersliderModule } from './store'
// import SearchAdapterFactory from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory'
// import { getSearchAdapter } from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory'

export const BannersliderModule: StorefrontModule = async function ({ app, store, router, moduleConfig, appConfig }) {
  store.registerModule('bannerslider', bannersliderModule);

  /* const searchAdapter = await getSearchAdapter()
    searchAdapter.registerEntityType('banner', {
      queryProcessor: (query) => {
        return query
      },
      resultPorcessor: (resp, start, size) => {
        return this.handleResult(resp, 'banner', start, size)
      }
    }) */
}
