import { SearchQuery } from 'storefront-query-builder'

const productActions = {
  async findProductOption ({ dispatch }, { serverItem }) {
    if (serverItem.product_type === 'configurable') {
      let query = new SearchQuery()
      query = query.applyFilter({ key: 'configurable_children.sku', value: { eq: serverItem.sku } })

      const { items } = await dispatch('product/findProducts', {
        query,
        start: 0,
        size: 1,
        options: {
          populateRequestCacheTags: false,
          prefetchGroupProducts: false,
          separateSelectedVariant: true
        }
      }, { root: true })

      // workaround for configurable products with custom options (remove last part of the sku)
      if (typeof items[0] === 'undefined') {
        items[0] = {};
        items[0].sku = serverItem.sku.substr(0, serverItem.sku.lastIndexOf('-'));
      }
      return items.length >= 1 ? { sku: items[0].sku, childSku: serverItem.sku } : null
    }

    return { sku: serverItem.sku }
  },
  async getProductVariant ({ dispatch }, { serverItem }) {
    try {
      let singleProduct
      do {
        const options = await dispatch('findProductOption', { serverItem })
        singleProduct = await dispatch('product/single', { options }, { root: true })

        serverItem.sku = options.sku
      } while (singleProduct === null && serverItem.sku.indexOf('-') !== -1)

      return {
        ...singleProduct,
        server_item_id: serverItem.item_id ?? serverItem.server_item_id,
        qty: serverItem.qty,
        server_quote_id: serverItem.quote_id,
        product_option: serverItem.product_option || singleProduct.product_option
      }
    } catch (e) {
      console.error('Could not find product variant:' + e)
      return null
    }
  }
}

export default productActions
