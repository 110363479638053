import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'
import { Logger } from '@vue-storefront/core/lib/logger'
import { CartService } from '@vue-storefront/core/data-resolver'
import {
  preparePaymentMethodsToSync,
  prepareShippingInfoForUpdateTotals,
  createOrderData,
  createShippingInfoData
} from '@vue-storefront/core/modules/cart/helpers'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

const totalsActions = {
  async updateTotals ({ commit }, payload) {
    commit(types.CART_UPD_TOTALS, payload)
  },
  async getTotals (context, { addressInformation, hasShippingInformation }) {
    if (hasShippingInformation) {
      return CartService.setShippingInfo(addressInformation)
    }

    return CartService.getTotals()
  },
  async updateTotalsFromServer ({ commit, getters, rootGetters, dispatch, state }, { addressInformation, hasShippingInformation }) {
    const { resultCode, result } = await dispatch('getTotals', { addressInformation, hasShippingInformation })

    if (resultCode === 200) {
      const totals = result.totals || result
      Logger.info('Overriding server totals. ', 'cart', totals)()
      const itemsAfterTotal = prepareShippingInfoForUpdateTotals(totals.items)

      for (const key of Object.keys(itemsAfterTotal)) {
        const item = itemsAfterTotal[key]
        const product = { server_item_id: item.item_id, totals: item, qty: item.qty }

        if (state.cartItems.find(p => p.server_item_id === product.server_item_id)) {
          await dispatch('updateItem', { product })
        } else {
          const serverItem = {
            ...product,
            ...{ sku: item.extension_attributes.sku }
          }
          await dispatch('addServerItem', { serverItem })
          await dispatch('updateItem', { product })
        }
      }

      /* remove items not existing in server cart  */
      state.cartItems.filter((p) => { return !itemsAfterTotal[p.server_item_id] }).forEach((cartItem) => {
        commit(types.CART_DEL_ITEM, { product: cartItem })
      })
      /* remove items not existing in server cart */

      commit(types.CART_UPD_TOTALS, { itemsAfterTotal, totals, platformTotalSegments: totals.total_segments })
      commit(types.CART_SET_TOTALS_SYNC)

      // we received payment methods as a result of this call, updating state
      if (result.payment_methods && getters.canUpdateMethods) {
        const { uniqueBackendMethods, paymentMethods } = preparePaymentMethodsToSync(
          result.payment_methods.map(method => ({ ...method, is_server_method: true })),
          rootGetters['checkout/getNotServerPaymentMethods']
        )
        dispatch('checkout/replacePaymentMethods', paymentMethods, { root: true })
        EventBus.$emit('set-unique-payment-methods', uniqueBackendMethods)
      }

      return
    }

    Logger.error(result, 'cart')()
  },
  async syncTotals ({ dispatch, getters, rootGetters }, payload: { forceServerSync: boolean, methodsData?: any } = { forceServerSync: false, methodsData: null }) {
    EventBus.$emit('cart-loading')

    const methodsData = payload ? payload.methodsData : null
    await dispatch('pullMethods', { forceServerSync: payload.forceServerSync })

    if (getters.canSyncTotals && (getters.isTotalsSyncRequired || payload.forceServerSync)) {
      const shippingMethodsData = methodsData || createOrderData({
        shippingDetails: rootGetters['checkout/getShippingDetails'],
        shippingMethods: rootGetters['checkout/getShippingMethods'],
        paymentMethods: rootGetters['checkout/getPaymentMethods'],
        paymentDetails: rootGetters['checkout/getPaymentDetails']
      })

      if (shippingMethodsData.country) {
        await dispatch('updateTotalsFromServer', {
          hasShippingInformation: shippingMethodsData.method_code || shippingMethodsData.carrier_code,
          addressInformation: createShippingInfoData(shippingMethodsData)
        })
        EventBus.$emit('cart-loaded')
        return
      }

      Logger.error('Please do set the tax.defaultCountry in order to calculate totals', 'cart')()
    }
  }
}

export default totalsActions
